<script setup lang="ts">
import type { PropType } from 'vue';

export type BaseDatagridColumns = {
  label: string;
  key: string;
  fixed?: boolean | 'left' | 'right';
  align?: 'left' | 'right';
  sortable?: boolean;
  formatter?: (row: any, column: any, cellValue: any, index: number) => string;
  width?: number;
  sortMethod?: (a: any, b: any) => number;
};

defineEmits(['sort-change', 'row-click', 'on-page-change']);

const props = defineProps({
  isLoading: {
    type: Boolean,
  },
  columns: {
    type: Array as PropType<BaseDatagridColumns[]>,
  },
  entries: {
    type: Array as PropType<Record<any, any>>,
    default: () => [],
  },
  sort: {
    type: Object as PropType<{
      prop: string;
      order: 'ascending' | 'descending';
    }>,
    default: () => {},
  },
  paginate: {
    type: Boolean,
  },
  totalItems: {
    type: Number,
    default: 0,
  },
  pageCount: {
    type: Number,
    default: 0,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
  emptyText: String,
});
</script>

<template>
  <div
    class="base-datagrid w-full h-full border-gray-200 border rounded p-1 bg-white"
  >
    <el-table
      v-loading="props.isLoading"
      :data="props.entries"
      :default-sort="props.sort"
      :empty-text="props.emptyText"
      @sort-change="(v) => $emit('sort-change', v)"
      @row-click="(v) => $emit('row-click', v)"
    >
      <el-table-column
        class="cursor-pointer"
        v-for="(column, index) in props.columns"
        :key="`${column.key}-${index}`"
        :prop="column.key"
        :label="column.label"
        :fixed="column.fixed"
        :align="column.align"
        :header-align="column.align ?? null"
        :sortable="column.sortable"
        :width="column.width"
        :formatter="column.formatter"
        :sort-method="column.sortMethod"
      >
        <template #default="scope">
          <slot
            :name="`row-data-${column.key}`"
            :row="scope.row"
            :key="column.key"
          >
            {{
              column.formatter
                ? column.formatter(scope.row, column, scope.row[column.key])
                : scope.row[column.key]
            }}
          </slot>
        </template>
      </el-table-column>
      <template #empty>
        <div class="flex flex-col items-center p-4">
          {{ props.emptyText }}
          <slot name="empty"></slot>
        </div>
      </template>
    </el-table>
    <el-pagination
      v-if="props.paginate"
      layout="prev, pager, next"
      :current-page="props.currentPage"
      :total="props.totalItems"
      :page-count="props.pageCount"
      :pager-count="11"
      :page-size="15"
      size="large"
      hide-on-single-page
      @update:current-page="(page) => $emit('on-page-change', page)"
      @change="(page) => $emit('on-page-change', page)"
    />
  </div>
</template>

<style>
.el-table--fit {
  height: calc(100% - 35px);
}
</style>
